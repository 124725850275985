import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import TextField from "@mui/material/TextField";
import { LocationType, TeamType } from "../../../types/types";
import { dbCreateLocation, dbLocationType, dbUpdateLocation } from "../../../api/api";
import NotificationService from "../../../components/notification/NotificationService";
import { Box, Card, CardActions, CardContent, CardHeader, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { createNewLocation, updateLocation } from "../../../features/locations/locationsSlice";

interface Props {
  selectedTeam?: TeamType;
}

const CreateLocation = (props: Props) => {
  const dispatch = useAppDispatch();
  const editingLocation = useAppSelector((state) => state.locations.editingLocation);
  const [locationName, setLocationName] = useState(editingLocation?.location ?? "");
  const locations = useAppSelector((state) => state.locations.locations);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const tournament = useAppSelector((state) => state.tournament.tournament);

  const updateLocationHandler = () => {
    if (!editingLocation) return;

    dbUpdateLocation({
      id: editingLocation.id,
      location: locationName,
      delay: editingLocation.delay,
      tournament: tournament.id,
    })
      .then(() => {
        const body = {
          id: editingLocation.id,
          location: locationName,
          tournament: tournament,
          delay: editingLocation.delay,
        } as LocationType;

        dispatch(updateLocation(body));
        navigate(ROUTES.ADMIN_LOCATIONS);
        NotificationService.showToast({
          severity: "success",
          text: "Banen blev opdateret.",
          duration: 3000,
        });
      })
      .catch((error) => {
        NotificationService.showToast({
          severity: "error",
          text: "Kunne ikke opdatere banen.",
          duration: 3000,
        });
        console.error(error);
      });
  };

  const createLocationHandler = () => {
    const locationExist = locations.some((location) => location.location === locationName);
    if (locationExist) {
      NotificationService.showToast({
        text: "Location already exists",
        severity: "error",
        duration: 3000,
      });
      return;
    }

    const body = {
      tournament: tournament.id,
      location: locationName,
      delay: 0,
    } as dbLocationType;

    dbCreateLocation(body)
      .then((id) => {
        dispatch(createNewLocation({ id, location: locationName, tournament: tournament, delay: 0 }));
        navigate(ROUTES.ADMIN_LOCATIONS);
      })
      .catch((e) => {
        console.error(e);
        NotificationService.showToast({
          severity: "error",
          text: `Location '${locationName}' could not be created.`,
          duration: 3000,
        });
      });
  };

  useEffect(() => {
    if (locationName.length > 12) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [locationName, open]);

  return (
    <>
      <Container maxWidth={"md"}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex" spacing={4}>
            <Typography variant="h4">{editingLocation ? "Opdater bane" : "Opret bane"}</Typography>
          </Stack>
          <Card>
            <form autoComplete="off" noValidate onSubmit={() => {}}>
              <Card>
                <CardHeader subheader="Udfyld følgende oplysninger:" title={editingLocation ? "Opdater bane" : "Ny bane"} />
                <CardContent sx={{ pt: 0 }}>
                  <Box sx={{ m: -1.5 }}>
                    <Grid container>
                      <Grid xs={12} md={6} padding={1} item>
                        <TextField
                          fullWidth
                          label="Navn"
                          name="location"
                          onChange={(e) => setLocationName(e.target.value)}
                          required
                          value={locationName}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={() => navigate(ROUTES.ADMIN_LOCATIONS)}>
                    Fortryd
                  </Button>
                  <Button
                    disabled={hasError || !locationName}
                    variant="contained"
                    onClick={editingLocation ? updateLocationHandler : createLocationHandler}
                  >
                    {editingLocation ? "Opdater" : "Opret"}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default CreateLocation;
