import Topbar from "../../../components/Topbar";
import { useAppSelector } from "../../../hooks";
import TabBar from "../../tabbar";

export default function Tournament() {
  const settings = useAppSelector((state) => state.settings.settings);
  return (
    <>
      <Topbar />
      <div className="tournament">
        <div className="welcome-container">
          <h1>{settings?.title}</h1>
          <p>{settings?.text}</p>
        </div>
        {settings?.rules && (
          <div className="rules">
            <h2 className="subheader">Rules</h2>
            <a target="_blank" href={settings?.rules}>
              Tournament rules
            </a>
          </div>
        )}
        {
          <div className="sponsors">
            <h2 className="subheader">Credits</h2>

            <div className="sponsors-container">
              <div className="sponsor">
                <img
                  src="https://tamil-football.dk/logo/dtsf.png"
                  onClick={() => window.open("https://www.facebook.com/Dtsfdk", "_blank")}
                />
              </div>
            </div>
          </div>
        }
      </div>
      <TabBar />
    </>
  );
}
