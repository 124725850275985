import { useAppSelector } from "../../hooks";
import {
  getDraws,
  getGoalDifference,
  getGoalsAway,
  getGoalsFor,
  getLoses,
  getPlays,
  getPoints,
  getWins,
  sortTeams,
} from "../../utils";
import ballLogo from "../../assets/icons/ball-logo.png";
import { MATCH_TYPE } from "../../types/types";

function Groups() {
  const groupMatches = useAppSelector((state) => state.matches.groupMatches).filter(
    (match) => match.matchType === MATCH_TYPE.GROUP_MATCH
  );
  const selectedPulje = useAppSelector((state) => state.puljer.selectedPulje);
  const _groups = useAppSelector((state) => state.groups.groups);
  const groups = _groups.filter((group) => (group.pulje as any).id === selectedPulje?.id);

  return (
    <>
      {groups.map((group) => {
        return (
          <div key={group.id} className="table">
            <div className="table-content">
              {
                <div className="row header">
                  <div className="club-column">
                    <p className="group-name">GROUP {group.name.toUpperCase()}</p>
                  </div>
                  <div className="points-column">
                    <p className="letter">P</p>
                    <p className="letter">W</p>
                    <p className="letter">D</p>
                    <p className="letter">L</p>
                    <p className="letter">F</p>
                    <p className="letter">A</p>
                    <p className="letter">GD</p>
                    <p className="letter">PTS</p>
                  </div>
                </div>
              }
            </div>
            <div className="table-content">
              {group.teams &&
                sortTeams(group.teams, groupMatches).map((team, index) => {
                  return (
                    <div key={team.id} className="row">
                      <div className="club-column">
                        <p className="order-column">{index + 1}</p>
                        <img src={team.club?.logo ? team.club?.logo : ballLogo} alt="" height={14} />
                        <span className="team-name">{team.teamName}</span>
                      </div>

                      <div className="points-column">
                        <p className="number">{getPlays(team.id, groupMatches)}</p>
                        <p className="number">{getWins(team.id, groupMatches)}</p>
                        <p className="number">{getDraws(team.id, groupMatches)}</p>
                        <p className="number">{getLoses(team.id, groupMatches)}</p>
                        <p className="number">{getGoalsFor(team.id, groupMatches)}</p>
                        <p className="number">{getGoalsAway(team.id, groupMatches)}</p>
                        <p className="number">{getGoalDifference(team.id, groupMatches)}</p>
                        <p className="number" style={{ color: "white" }}>
                          {getPoints(team.id, groupMatches)}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Groups;
