import useWindowDimensions from "../../hooks/useWindowDimensions";
import Matches from "../admin/matches/matches";
import { useCallback, useEffect, useMemo, useState } from "react";
import Groups from "../admin/groups/groups";
import Teams from "../admin/teams/teams";
import Locations from "../admin/locations/locations";
import SettingsView from "../admin/settings/settings";
import { ROUTES } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Users } from "../admin/users/users";
import CreateUser from "../admin/users/createUser";
import { TopNav } from "./dashboard/top-nav";
import { SideNav } from "./dashboard/side-nav";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Puljer from "../admin/puljer/puljer";
import Overview from "../admin/overview/overview";
import CreateGroup from "../admin/groups/createGroup";
import CreateTeam from "../admin/teams/createTeam";
import CreateLocation from "../admin/locations/createLocation";
import CreatePulje from "../admin/puljer/createPulje";
import KnockOut from "../admin/knockout/knockout";
import GenerateTournament from "../admin/matches/generateTournament";
import CreateMatch from "../admin/matches/createMatch";
import { useAppSelector } from "../../hooks";
import Login from "../../components/login";
import { ROLES } from "../../types/types";
import NotificationService from "../../components/notification/NotificationService";
import TableView from "../admin/table/table";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

function NewAdmin() {
  const { isDesktop } = useWindowDimensions();
  const [openNav, setOpenNav] = useState(false);
  const pathname = useLocation().pathname;
  const admin = useAppSelector((state) => state.app.admin);
  const location = useLocation();

  const navigate = useNavigate();

  const getView = () => {
    switch (location.pathname) {
      case ROUTES.ADMIN:
        return <Matches />;
      case ROUTES.ADMIN_MATCHES:
        return <Matches />;
      case ROUTES.ADMIN_CREATE_MATCH:
        return <CreateMatch />;
      case ROUTES.ADMIN_GENERATE_TOURNAMENT:
        return <GenerateTournament />;
      case ROUTES.ADMIN_PULJER:
        return <Puljer />;
      case ROUTES.ADMIN_CREATE_PULJE:
        return <CreatePulje />;
      case ROUTES.ADMIN_GROUPS:
        return <Groups />;
      case ROUTES.ADMIN_CREATE_GROUP:
        return <CreateGroup />;
      case ROUTES.ADMIN_TEAMS:
        return <Teams />;
      case ROUTES.ADMIN_CREATE_TEAM:
        return <CreateTeam />;
      case ROUTES.ADMIN_KNOCK_OUT:
        return <KnockOut />;
      case ROUTES.ADMIN_LOCATIONS:
        return <Locations />;
      case ROUTES.ADMIN_CREATE_LOCATION:
        return <CreateLocation />;
      case ROUTES.ADMIN_SETTINGS:
        return <SettingsView />;
      case ROUTES.ADMIN_TABLE:
        return <TableView />;
      default:
        return <h1>404 page does not exist</h1>;
    }
  };

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(() => {
    handlePathnameChange();
  }, [pathname]);

  const isValidAdmin = useMemo(() => {
    switch (admin?.role) {
      case ROLES.SUPER_ADMIN:
        return true;
      case ROLES.ADMIN:
        return true;
      case ROLES.EDITOR:
        NotificationService.showToast({ text: "You do not have sufficient access to this page", severity: "error" });
        return false;
      default:
        return false;
    }
  }, [admin?.role]);

  return (
    <>
      {!isValidAdmin ? (
        <Login open={true} returnCallback={() => {}} />
      ) : (
        <div style={{ width: "100%", height: "100%", minHeight: "100vh", backgroundColor: "white" }}>
          <TopNav onNavOpen={() => setOpenNav(true)} />
          <SideNav onClose={() => setOpenNav(false)} open={openNav} />
          <LayoutRoot>
            <LayoutContainer>
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                }}
              >
                {getView()}
              </Box>
            </LayoutContainer>
          </LayoutRoot>
        </div>
      )}
    </>
  );
}

export default NewAdmin;
