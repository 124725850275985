import { useMemo, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControlLabel,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { dbUpdateSettings, dbUpdateTournament } from "../../../api/api";
import { setTournament } from "../../../features/tournament/tournamentSlice";
import NotificationService from "../../../components/notification/NotificationService";
import { Settings, Tournament } from "../../../types/types";
import settings, { setSettings } from "../../../features/settings/settings";
import ErrorPage from "../../utils/errorPage";

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

export default function SettingsView() {
  const settings = useAppSelector((state) => state.settings.settings);

  if (!settings) return <ErrorPage />;

  const _gapTime = useAppSelector((state) => state.settings.settings?.gapTime);
  const _rules = useAppSelector((state) => state.settings.settings?.rules);
  const defaultTournamentName = useAppSelector((state) => state.tournament.tournament.name);
  const _title = useAppSelector((state) => state.settings.settings?.title);
  const _text = useAppSelector((state) => state.settings.settings?.text);
  const _tournamentDate = useAppSelector((state) => state.tournament.tournament.date);
  const [tournamentDate, setTournamentDate] = useState<Moment | null>(moment.unix(_tournamentDate));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const groupMatches = useAppSelector((state) => state.matches.groupMatches);
  const [gapTime, setgapTime] = useState(_gapTime ?? 0 / 60);
  const [tournamentName, setTournamentName] = useState(defaultTournamentName);
  const [title, setTitle] = useState(_title ?? "");
  const [text, setText] = useState(_text ?? "");
  const [isPublic, setIsPublic] = useState(settings.isPublic);
  const [rules, setRules] = useState(settings.rules);

  const save = () => {
    const settingsUpdate: Settings = {
      id: settings.id,
      gapTime,
      isPublic,
      rules,
      text,
      title,
      tournament: settings.tournament,
    };

    dbUpdateSettings(settingsUpdate)
      .then(() => {
        dispatch(setSettings(settingsUpdate));
        NotificationService.showToast({
          text: "Opdaterede indstillinger",
          severity: "success",
          duration: 5000,
        });
      })
      .catch((e) => {
        console.error(e);
        NotificationService.showToast({
          severity: "error",
          text: "Kunne ikke opdatere indstillinger",
          duration: 5000,
        });
      });
  };

  const isDirty = useMemo(() => {
    return (
      tournamentDate?.unix() !== _tournamentDate ||
      tournamentName !== defaultTournamentName ||
      isPublic !== settings.isPublic ||
      gapTime !== _gapTime ||
      title !== _title ||
      text !== _text ||
      rules !== _rules
    );
  }, [
    tournamentName,
    isPublic,
    defaultTournamentName,
    settings.isPublic,
    tournamentDate,
    _tournamentDate,
    gapTime,
    _gapTime,
    title,
    _title,
    text,
    _text,
    rules,
    _rules,
  ]);

  useEffect(() => {
    if (tournamentName?.length > 18) {
      setNameErrorMessage("Max 18 characters");
    } else {
      setNameErrorMessage("");
    }
  }, [tournamentName]);

  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="h4">Indstillinger</Typography>
          <div>
            <Button
              disabled={!isDirty}
              startIcon={
                <SvgIcon fontSize="small">
                  <CheckIcon />
                </SvgIcon>
              }
              variant="contained"
              onClick={save}
            >
              Gem
            </Button>
          </div>
        </Stack>

        <Card>
          <CardHeader subheader="Opdater turneringsoplysninger" title="Generelt" />
          <Divider />
          <CardContent>
            <Stack spacing={3} sx={{ maxWidth: 400 }}>
              <TextField
                fullWidth
                label="Turneringsnavn"
                name="tournament-name"
                value={tournamentName}
                onChange={(e) => setTournamentName(e.target.value)}
                disabled
              />
              <DatePicker
                value={tournamentDate}
                label="Dato og tid"
                onChange={(value) => setTournamentDate(value)}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD/MM/YYYY HH:mm"
                disabled
              />
            </Stack>
          </CardContent>
          <Divider />
        </Card>
        <Card>
          <CardHeader subheader="Opdater info på 'Tournament' siden i app'en." title="Turnering" />
          <Divider />
          <CardContent>
            <Stack spacing={3} sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                label="Titel"
                name="tournament-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                fullWidth
                label="Tekst"
                name="tournament-text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                maxRows={3}
              />
              <TextField
                fullWidth
                label="Link til regelsæt"
                name="tournament-rules"
                value={rules}
                onChange={(e) => setRules(e.target.value)}
              />
            </Stack>
          </CardContent>
          <Divider />
        </Card>
        <Card>
          <CardHeader subheader="Opdater oplysninger vedrørende kampene" title="Kampe" />
          <Divider />
          <CardContent>
            <Stack spacing={3} sx={{ maxWidth: 400 }}>
              <TextField
                fullWidth
                label="Pause mellem kampe (minutter)"
                name="group-match-gap-time"
                value={gapTime}
                type="number"
                onChange={(e) => setgapTime(Number(e.target.value))}
                disabled={groupMatches.length !== 0}
              />
            </Stack>
          </CardContent>
          <Divider />
        </Card>
        <Card>
          <CardHeader subheader="" title="Synlighed" />
          <Divider />
          <CardContent>
            <Stack spacing={3} sx={{ maxWidth: 400 }}>
              <FormControlLabel
                sx={{ justifyContent: "flex-end" }}
                labelPlacement="start"
                control={<Switch checked={isPublic} onChange={(e: any) => setIsPublic(e.target.checked)} />}
                label="Offentligør turnering"
              />
            </Stack>
          </CardContent>
          <Divider />
        </Card>
      </Stack>
    </Container>
  );
}
