import ListBulletIcon from "@heroicons/react/24/solid/ListBulletIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import SettingsIcon from "@heroicons/react/24/solid/Cog6ToothIcon";
import MapPinIcon from "@heroicons/react/24/solid/MapPinIcon";
import UserGroupIcon from "@heroicons/react/24/solid/UserGroupIcon";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { SvgIcon } from "@mui/material";
import { ROUTES } from "../../../constants";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GrassIcon from "@mui/icons-material/Grass";

export const items = [
  {
    title: "Kampprogram",
    paths: [
      ROUTES.ADMIN,
      ROUTES.ADMIN_MATCHES,
      ROUTES.ADMIN_GENERATE_TOURNAMENT,
      ROUTES.ADMIN_CREATE_MATCH,
      ROUTES.ADMIN_TABLE,
    ],
    icon: (
      <SvgIcon fontSize="small">
        <ListBulletIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
  {
    title: "Puljer",
    paths: [ROUTES.ADMIN_PULJER, ROUTES.ADMIN_CREATE_PULJE],
    icon: (
      <SvgIcon fontSize="small">
        <EmojiEventsIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
  {
    title: "Grupper",
    paths: [ROUTES.ADMIN_GROUPS, ROUTES.ADMIN_CREATE_GROUP],
    icon: (
      <SvgIcon fontSize="small">
        <GroupWorkIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
  {
    title: "Hold",
    paths: [ROUTES.ADMIN_TEAMS, ROUTES.ADMIN_CREATE_TEAM],
    icon: (
      <SvgIcon fontSize="small">
        <UserGroupIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
  {
    title: "Knockout",
    paths: [ROUTES.ADMIN_KNOCK_OUT],
    icon: (
      <SvgIcon fontSize="small">
        <SportsMmaIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
  {
    title: "Baner",
    paths: [ROUTES.ADMIN_LOCATIONS, ROUTES.ADMIN_CREATE_LOCATION],
    icon: (
      <SvgIcon fontSize="small">
        <MapPinIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
  {
    title: "Indstillinger",
    paths: [ROUTES.ADMIN_SETTINGS],
    icon: (
      <SvgIcon fontSize="small">
        <SettingsIcon />
      </SvgIcon>
    ),
    disabled: false,
    external: false,
  },
];
